@import "src/styles/media";

.carousel {
  display: flex;
  gap: 24px;
  flex-direction: column-reverse;
  align-items: center;
  @media (min-width: $sm) {
    gap: 44px;
  }
  @media (min-width: $md) {
    flex-direction: row;
    align-items: center;
    gap: 55px;
  }
  @media (min-width: $lg) {
    gap: 70px;
    padding: 0 84px;
  }

  .left {
    max-width: 100%;
    @media (min-width: $md) {
      max-width: 380px;
    }
  }

  .right {
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  &__image {
    max-width: 100%;
    display: block;
    border-radius: 8px;
  }

  .slider {
    position: relative;
    overflow: hidden;

    img {
      max-width: 100%;
      display: block;
      height: 270px;
      object-fit: cover;
      object-position: center;
      width: 100%;
      @media (min-width: $xs) {
        height: 320px;
      }
      @media (min-width: $sm) {
        height: 445px;
      }
      @media (min-width: $md) {
        height: 332px;
      }
      @media (min-width: $md) {
        height: 348px;
      }
    }
  }
}


.carousel-arrow {
  display: flex;
  column-gap: 16px;
  margin-top: 24px;
  @media (min-width: $sm) {
    margin-top: 32px;
  }

  &__arrow {
    width: 72px;
    height: 48px;
    padding: 12px 24px 12px 24px;
    border-radius: 30px;
    background: #E7E8FC;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover, &:disabled {
      background: #e7e8fc6e;

      svg {
        path {
          stroke: #0d11412e;
        }
      }
    }
  }

  &__slider-count {
    margin: 24px 0;
    min-width: 47px;
    font-size: 23px;
    line-height: 26px;
  }
}
