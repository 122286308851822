@import "src/styles/media";

.base-content-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: var(--color-light-grey);
  border-radius: 16px;
  padding: 44px 20px;
  @media (min-width: $sm) {
    padding: 44px 86px;
  }
  @media (min-width: $md) {
    padding: 64px 107px;
  }
  @media (min-width: $lg) {
    padding: 72px 84px;
  }

  &__img {
    width: 62px;
    height: auto;
    @media (min-width: $sm) {
      width: 98px;
    }
  }

  &__subtitle {
    max-width: 525px;
    @media (min-width: $md) {
      font-size: 20px !important;
      line-height: 26px !important;
    }
  }

  &__description {
    max-width: 617px;
    @media (min-width: $md) {
      font-size: 20px !important;
      line-height: 26px !important;
    }
    @media (min-width: $lg) {
      max-width: 766px;
    }
  }
}
