:root {
  --color-primary-black: #0D1141;
  --color-deep-black: #111215;
  --color-white: #fff;
  --color-blue: #4833F5;
  --color-light-grey: #F7F8FC;
  --color-light-grey2: #EAECF2;
  --color-grey: #7B7F89;
  --color-grey-text: #727686;
  --color-red: #ff453a;

  --font-primary: 'Aeonik Pro Regular', sans-serif;
  --font-secondary: 'Aeonik Pro Medium', sans-serif;
}
