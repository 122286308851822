@import "src/styles/media";

.callback-modal {
  background: var(--color-light-grey);

  &__header {
    padding: 20px;
    @media (min-width: $sm) {
      padding: 32px;
    }
  }

  &__content {
    border-radius: 24px;
    background: var(--color-white);
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: $sm) {
      padding: 32px 67px;
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
