@import "src/styles/media";

.accordion {
  @media (min-width: $lg) {
    padding: 0 84px;
  }

  &__content {
    padding-bottom: 16px;

    ul {
      li {
        position: relative;
        padding-left: 25px;

        &:before {
          content: '';
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: black;
          left: 10px;
          top: 8px;
        }
      }
    }
  }
}
