@import "src/styles/media";

.collapse {
  width: 100%;
}


.panel {
  &:not(:last-child) {
    margin-bottom: 16px;
    border-bottom: 1.5px solid #E6E8EE;
  }

  &.active {
    .panel__content {
      visibility: visible;
      opacity: 1;
    }

    &.arrow-moving {
      .panel__label {
        &:before {
          transform: rotate(0deg);
        }

        .arrow {
          transform: rotate(0deg);
        }
      }
    }
  }

  &__label {
    outline: none;
    border: none;
    background: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
      transition: 0.2s linear;
      color: var(--color-primary-blue-hover);
    }

    &:disabled {
      color: var(--color-usual-grey);
    }
  }

  &__icon {
    background: var(--color-light-grey);
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: $sm) {
      width: 54px;
      height: 54px;
    }
    .arrow {
      will-change: transform;
      transform: rotate(90deg);
      transition: transform 0.2s linear;
    }
  }

  &__inner {
    overflow: hidden;
    will-change: height;
    transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
    margin-top: 16px;
  }

  &__content {
    transition: opacity 0.3s linear 0.18s;
  }
}
