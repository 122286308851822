@font-face {
  font-family: "Object Sans";
  src: url("../assets/fonts/ObjectSans-Regular.woff2") format("woff2"),
    url("../assets/fonts/ObjectSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Object Sans Slanted";
  src: url("../assets/fonts/ObjectSans-Slanted.woff2") format("woff2"),
    url("../assets/fonts/ObjectSans-Slanted.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Object Sans";
  src: url("../assets/fonts/ObjectSans-Heavy.woff2") format("woff2"),
    url("../assets/fonts/ObjectSans-Heavy.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Object Sans";
  src: url("../assets/fonts/ObjectSans-HeavySlanted.woff2") format("woff2"),
    url("../assets/fonts/ObjectSans-HeavySlanted.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Pro Medium';
  src: url('../assets/fonts/Aeonik Pro Medium.woff');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Aeonik Pro Regular';
  src: url('../assets/fonts/Aeonik Pro Regular.woff');
}
