@import "src/styles/media";

.button {
  font-family: var(--font-primary);
  font-size: 3.2vw;
  line-height: 3.5vw;
  transition: all 200ms linear;
  transition-property: color, background-color, box-shadow, border-color;
  display: block;
  border: none;
  cursor: pointer;
  outline: 0;
  color: var(--color-white);
  background-color: var(--color-blue);
  appearance: none;
  border-radius: 30px;
  padding: 13px 16px;
  font-weight: 500;
  @media (min-width: $xs) {
    font-size: 12px;
    line-height: 14px;
  }
  @media (min-width: $sm) {
    padding: 13px 24px;
    font-size: 16px;
    line-height: 18px;
  }

  &:hover {
    background-color: #3b29cc;
  }

  &:disabled {
    cursor: default;
    box-shadow: none;
  }

  &.fluid {
    width: 100%;
  }

  &.icon {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}

.view-primary {
  //background-color: $blue;

  &:hover {
    //background-color: $blue-hover;
  }
}

.size-lg {
  letter-spacing: 1.25px;
  font-weight: 500;
  padding: 10px 15px;

  &.icon {
    gap: 10px;
  }
}

.size-sm {
  font-size: 12px;
  font-weight: 400;
  padding: 3px 15px;

  &.icon {
    gap: 6px;
  }
}

.size-xs {
  font-size: 12px;
  padding: 4px 9px;
}

.color-white {
  background: var(--color-white);
  color: var(--color-primary-black);
}

.color-black {
  color: var(--color-primary-black);
}

.button-group {
  display: flex;
  margin-left: -6px;
  margin-right: -6px;

  .button {
    margin-left: 6px;
    margin-right: 6px;
  }

  &.gap-8 {
    margin-left: -8px;
    margin-right: -8px;

    .button,
    & > * {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  &.gap-16 {
    margin-left: -16px;
    margin-right: -16px;

    .button {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
}
