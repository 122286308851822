@import "src/styles/media";

.calculator {
  background: var(--color-light-grey);
  padding: 32px 16px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media (min-width: $sm) {
    gap: 44px;
    padding: 44px;
  }
  @media (min-width: $md) {
    flex-direction: row;
    gap: 72px;
  }
  @media (min-width: $lg) {
    gap: 90px;
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: $md) {
      max-width: 300px;
      width: 100%;
      align-items: flex-start;
    }
    @media (min-width: $lg) {
      max-width: 450px;
    }
  }

  &__description {
    @media (min-width: $sm) {
      max-width: 335px;
    }
    @media (min-width: $lg) {
      max-width: 375px;
    }
  }

  .calculate-delivery {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 24px;
    @media (min-width: $sm) {
      margin-top: 32px;
      gap: 8px;
    }

    &__cost {
      font-size: 6.9vw;
      line-height: 8vw;
      margin-top: 8px;
      @media (min-width: $xs) {
        font-size: 26px;
        line-height: 30px;
      }
      @media (min-width: $sm) {
        font-size: 32px;
        line-height: 36px;
      }
    }
  }

  .small-row {
    @media (max-width: $xs) {
      margin-left: -4px;
      margin-right: -4px;
    }
  }

  .small-col {
    @media (max-width: $xs) {
      max-width: 50%;
      flex: 0 0 50%;
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}
