.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  .main {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
}
