@import "src/styles/media";

.advantage {
  border-radius: 24px;
  background: var(--color-light-grey);
  padding: 32px 24px;
  height: 156px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  @media (min-width: $sm) {
    padding: 32px 24px;
    height: 186px;
  }
  @media (min-width: $md) {
    height: 164px;
  }
  @media (min-width: $lg) {
    height: 202px;
  }
}
