@import "src/styles/media";

.header {
  height: var(--height);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 16px 24px;
  background: var(--color-white);
  border-radius: 0 0 24px 24px;
  transition: justify-content 3s ease;
  flex-direction: column;
  @media (min-width: $sm) {
    justify-content: space-between;
    flex-direction: row;
  }
  @media (min-width: $lg) {
    padding: 29px 44px;
  }

  &-wrapper {
    position: sticky;
    top: 0;
    z-index: 9;
  }

  &__img {
    display: block;
    width: 150px;
    height: auto;
    @media (min-width: $lg) {
      width: 162px;
    }
  }
}


.menu-links {
  display: flex;
  gap: 32px;
  margin-top: 24px;
  @media (min-width: $sm) {
    margin-top: 0;
  }
}


.social-links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
