*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--color-primary-black);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
