@import "src/styles/media";

.text {
  font-family: var(--font-primary);
  font-size: 18px;
  line-height: 22px;

  &.text-xxl {
    font-size: 5vw;
    line-height: 5.58vw;
    @media (min-width: $xs) {
      font-size: 18px;
      line-height: 20px;
    }
    @media (min-width: $sm) {
      font-size: 22px;
      line-height: 25px;
    }
    @media (min-width: $md) {
      font-size: 30px;
      line-height: 35px;
    }
    @media (min-width: $lg) {
      font-size: 34px;
      line-height: 39px;
    }
  }

  &.text-xl {
    font-size: 6.4vw;
    line-height: 7.4vw;
    @media (min-width: $xs) {
      font-size: 24px;
      line-height: 27px;
    }
    @media (min-width: $sm) {
      font-size: 28px;
      line-height: 32px;
    }
  }

  &.text-lg {
    font-size: 3.2vw;
    line-height: 4.5vw;
    @media (min-width: $xs) {
      font-size: 12px;
      line-height: 16px;
    }
    @media (min-width: $sm) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (min-width: $lg) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &.text-md {
    font-size: 3.85vw;
    line-height: 4vw;
    @media (min-width: $sm) {
      font-size: 14px;
      line-height: 16px;
    }
    @media (min-width: $md) {
      font-size: 16px;
      line-height: 18px;
    }
  }

  &.text-sm {
    font-size: 3.7vw;
    line-height: 4.1vw;
    @media (min-width: $xs) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &.text-xs {
    font-size: 13px;
    line-height: 20px;
  }

  &.text-xxs {
    font-size: 12px;
    line-height: 14px;
  }
}

h1 {
  &.text {
    font-family: var(--font-secondary);
    font-size: 5vw;
    line-height: 5.58vw;
    font-weight: 500;
    @media (min-width: $xs) {
      font-size: 18px;
      line-height: 20px;
    }
    @media (min-width: $sm) {
      font-size: 30px;
      line-height: 32px;
    }
    @media (min-width: $md) {
      font-size: 48px;
      line-height: 48px;
    }
    @media (min-width: $lg) {
      font-size: 54px;
      line-height: 54px;
    }
  }
}

h2 {
  &.text {
    font-family: var(--font-secondary);
    font-size: 4.8vw;
    line-height: 5.9vw;
    font-weight: 500;
    @media (min-width: $xs) {
      font-size: 18px;
      line-height: 22px;
    }
    @media (min-width: $sm) {
      font-size: 30px;
      line-height: 32px;
    }
    @media (min-width: $md) {
      font-size: 34px;
      line-height: 38px;
    }
    @media (min-width: $lg) {
      font-size: 38px;
      line-height: 42px;
    }
  }
}

h3 {
  &.text {
    font-family: var(--font-secondary);
    font-size: 5vw;
    line-height: 30px;
    font-weight: 500;
    @media (min-width: $sm) {
      font-size: 28px;
      line-height: 32px;
    }
  }
}

h4 {
  &.text {
    font-family: var(--font-secondary);
    font-size: 5vw;
    line-height: 30px;
    font-weight: 500;
    @media (min-width: $sm) {
      font-size: 26px;
    }
  }
}

h5 {
  &.text {
    font-family: var(--font-secondary);
    font-size: 5vw;
    line-height: 25px;
    font-weight: 500;
    @media (min-width: $sm) {
      font-size: 24px;
      line-height: 27px;
    }
  }
}

h6 {
  &.text {
    font-family: var(--font-secondary);
    font-size: 16px;
    line-height: 17px;
    font-weight: 400;
    //@media (min-width: $lg) {
    //  font-size: 18px;
    //  line-height: 19px;
    //}
  }
}

.color-primary {
  color: var(--color-primary-black);
}

.color-gray {
  color: var(--color-grey);
}

.color-white {
  color: var(--color-white);
}

.color-blue {
  color: var(--color-blue);
}

.color-red {
  color: var(--color-red);
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-regular {
  font-weight: 400;
}

.text-medium {
  font-weight: 500;
}

.text-semibold {
  font-weight: 600;
}

.text-bold {
  font-weight: 700;
}

.text-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.text-overflow-fade {
  position: relative;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;

  &::after {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    content: '';
    width: 2em;
    background-image: linear-gradient(270deg, black 35%, transparent);
  }
}

.text-transform-lowercase {
  text-transform: lowercase;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.text-decoration-underline {
  text-decoration: underline;
  text-decoration-color: var(--color-usual-grey);
}

.text-decoration-line-through {
  text-decoration: line-through;
  text-decoration-thickness: 1px;
  //@media (min-width: $lg) {
  //  text-decoration-thickness: 2px;
  //}
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-pre-line {
  white-space: pre-line;
}

.white-space-initial {
  white-space: initial;
}

.font-family-primary {
  font-family: var(--font-primary);
}

.font-family-secondary {
  font-family: var(--font-secondary);
}

.cursor-pointer {
  cursor: pointer;
}

.required {
  &:after {
    content: "*";
    color: var(--color-extra-red);
    margin-left: 2px;
  }
}

.required-color-grey {
  &.required {
    &:after {
      color: var(--color-text-grey);
    }
  }
}

.disable {
  //color: $gray-dark;
}
