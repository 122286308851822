@import "src/styles/media";

.footer {
  background-color: var(--color-deep-black);
  border-radius: 24px 24px 0 0;
  padding: 44px 24px 48px;
  @media (min-width: $sm) {
    padding: 44px;
  }

  &-wrapper {
    background: var(--color-light-grey);
  }

  &__logo {
    display: flex;
  }

  &__content {
    margin-top: 54px;
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    @media (min-width: $sm) {
      margin-top: 97px;
    }
    @media (min-width: $md) {
      flex-direction: row;
    }
    @media (min-width: $lg) {
      margin-top: 84px;
    }

    .addresses {
      display: flex;
      gap: 54px;
      flex-direction: column;
      @media (min-width: $sm) {
        flex-direction: row;
      }

      &__item {
        max-width: 237px;
      }
    }

    .socials {
      display: flex;
      margin-top: 16px;
      gap: 16px;
    }
  }

  .left {
    margin-top: 54px;
    @media (min-width: $md) {
      margin-top: 0;
    }
  }
}
